import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import BtnSolid from 'components/button/button-solid'

const InsuranceLogos = ({ className }) => {
  const data = useStaticQuery ( graphql`
    query InsuranceLogosQuery {
      delta_dental: file(name: { eq: "delta-dental-logo" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      metlife: file(name: { eq: "metlife-logo" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cigna: file(name: { eq: "cigna-logo" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      aetna: file(name: { eq: "aetna-logo" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      blue_cross: file(name: { eq: "blue-cross-blue-shield-logo" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      united_healthcare: file(name: { eq: "united-healthcare-logo" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ci_logo: file(name: { eq: "ci-logo-dark" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      connection_dental_network_logo: file(name: { eq: "connection-dental-logo" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      dental_network_america_logo: file(name: { eq: "dental-network-america" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      dha_logo: file(name: { eq: "dha" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      geha_logo: file(name: { eq: "geha" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      guardian_logo: file(name: { eq: "guardian" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      humana_logo: file(name: { eq: "hamana" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      lincoln_logo: file(name: { eq: "lincoln0logo" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      sunlife_logo: file(name: { eq: "sunlife" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      united_concordia_logo: file(name: { eq: "united-concordia" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      zelis_logo: file(name: { eq: "zelis_logo_pos_RGB_HR_-_online" }) {
        childImageSharp {
          fluid( maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `
  )

  // Set ImageData.
  const delta_dental = data.delta_dental?.childImageSharp?.fluid
  const metlife = data.metlife?.childImageSharp?.fluid
  const cigna = data.cigna?.childImageSharp?.fluid
  const aetna = data.aetna?.childImageSharp?.fluid
  const blue_cross = data.blue_cross?.childImageSharp?.fluid
  const united_healthcare = data.united_healthcare?.childImageSharp?.fluid
  const ci_logo = data.ci_logo?.childImageSharp?.fluid
  const connection_dental_network_logo = data.connection_dental_network_logo?.childImageSharp?.fluid
  const dental_network_america_logo = data.dental_network_america_logo?.childImageSharp?.fluid
  const dha_logo = data.dha_logo?.childImageSharp?.fluid
  const geha_logo = data.geha_logo?.childImageSharp?.fluid
  const guardian_logo = data.guardian_logo?.childImageSharp?.fluid
  const humana_logo = data.humana_logo?.childImageSharp?.fluid
  const lincoln_logo = data.lincoln_logo?.childImageSharp?.fluid
  const sunlife_logo = data.sunlife_logo?.childImageSharp?.fluid
  const united_concordia_logo = data.united_concordia_logo?.childImageSharp?.fluid
  const zelis_logo = data.zelis_logo?.childImageSharp?.fluid

  return (
    <div className="col-md-12 insurance-slider">
      <div className="row align-items-center">
        <div className="col-md-4">
          <Img fluid={delta_dental} alt="Delta Dental logo" className="mb-0 old-logos"/>
        </div>
        <div className="col-md-4">
          <Img fluid={metlife} alt="MetLife logo" className="mb-0 old-logos"/>
        </div>
        <div className="col-md-4">
          <Img fluid={cigna} alt="Cigna logo" className="mb-0 old-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={aetna} alt="Aetna logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={blue_cross} alt="Blue Cross Blue Shield logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={united_healthcare} alt="United Healthcare logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={ci_logo} alt="Careington logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={connection_dental_network_logo} alt="Connection Dental Network logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={dental_network_america_logo} alt="Dental Network America logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={dha_logo} alt="Dental Health Alliance logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={geha_logo} alt="GEHA logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={guardian_logo} alt="Guardian logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={humana_logo} alt="Humana logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={lincoln_logo} alt="Lincoln logo" className="new-logos"/>
        </div>
        <div className="col-md-4 new-logo-container">
          <Img fluid={sunlife_logo} alt="Sunlife logo" className="new-logos"/>
        </div>
        <div className="col-md-4 mb-3 new-logo-container">
          <Img fluid={united_concordia_logo} alt="United Concordia logo" className="new-logos"/>
        </div>
        <div className="col-md-4 mb-3 new-logo-container">
          <Img fluid={zelis_logo} alt="Zelis logo" className="new-logos"/>
        </div>
      </div>
      <h2 className="text-center text-black call"><strong>Questions? </strong>Give Us A Call</h2>
            <BtnSolid url="tel:3176439434" class="btn-rounded btn-solid bg-main cta-btn" label="(317) 643-9434" />
    </div>
  )
}

export default InsuranceLogos
