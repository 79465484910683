import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'
import Video from 'components/video-embed'
import { FaArrowAltCircleRight } from "@react-icons/all-files/fa/FaArrowAltCircleRight";

const DrVogtSection = ({ className, props }) => {
  const data = useStaticQuery(
    graphql`
      query {
        section2_bg: file(name: { eq:"section_4_bg-min" }) {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        dr_vogt: file(name: { eq: "best-dr-s-and-v2" }) {
          childImageSharp {
            fluid(maxWidth:600, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        office_video: file(name: { eq: "office-video" }) {
          childImageSharp {
            fluid(maxWidth:600, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const section2_bg = data.section2_bg.childImageSharp.fluid
  const dr_vogt = data.dr_vogt.childImageSharp.fluid
  const office_video = data.office_video.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="div"
      className="home-section-2-bg"
      fluid={section2_bg}
      backgroundColor={`#e9f4f8`}
      alt="Our Office - The Dentists at Gateaway Crossing"
      >
      <div id="home-section-2" className="page-section">
        <Container>
          <div className="col-lg-6 video-container">
            <div className ="col-md-12 bg-light">
              <h2>Meet Your<br /> <strong>McCordsville Dentist</strong></h2>
            </div>
            {/* <Video videoSrcURL="https://player.vimeo.com/video/455425077?autoplay=1" videoThumbSrc={office_video} /> */}

          <div className='embed-container'>
            <iframe src='https://player.vimeo.com/video/455425077' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
          </div>
          </div>
          <div className="col-lg-6 img-container">
            <Img fluid={dr_vogt} className="dr-vogt-img" alt="Home V2 1" alt="Dr. Matthew Vogt of The Dentists at Gateway Crossing" />
          </div>
        </Container>
        <Container>
          <div className= "col-lg-6">
            <div className="bg-light padding-20 m-b-20">
              <p className="subheader-small"> Dr. Matthew Vogt </p>
              <ul>
                <li>BS, Biology – Butler University</li>
                <li>DDS – Indiana University School of Dentistry</li>
                <li>Post-graduate training – Spear Education, Implant Pathway</li>
                <li>Favorite parts of job include comprehensive dentistry, dental implants, and revealing a patient's new smile to them for the first time</li>
                <li>Enjoys time with wife Hilary and their two rescue dogs, competitive golf, fitness, and pizza.</li>
              </ul>
            </div>
            <Link to="/about/dr-vogt"><button className="btn-rounded more-about btn-solid bg-main m-t-20">More about Dr. Matthew Vogt  <FaArrowAltCircleRight size="25px" className="text-white svg-icon-middle" /></button></Link>
          </div>
          <div className= "col-lg-6">
          <div className="bg-light dr-summers-sec m-b-20">
              <p className="subheader-small"> Dr. Maria Summers </p>
              <ul>
                <li>BS, Biomedical Sciences - University of Central Florida</li>
                <li>DDS, – Indiana University School of Dentistry</li>
                <li>Born in Venezuela, moved to US at age 15</li>
                <li>Retired Dental Officer from Indiana National Guard</li>
                <li>Enjoys eating, traveling, playing tennis, watching Formula 1, and time with husband Brandon and their two dogs</li>
              </ul>
            </div>
            <Link to="/about/dr-summers"><button className="btn-rounded more-about btn-solid bg-main m-t-20">More about Dr. Maria Summers  <FaArrowAltCircleRight size="25px" className="text-white svg-icon-middle" /></button></Link>
          </div>
        </Container>
      </div>
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(DrVogtSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection
