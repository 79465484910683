import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import TestimonialQuote from '/src/images/testimonial_quote.png'
import PositiveMin from '/src/images/positive-min.png'
import { FaStar } from "@react-icons/all-files/fa/FaStar"

const TestimonialSlider = ({ className }) => {
  const data = useStaticQuery ( graphql`
    query TestimonialSliderQuery2 {
      doug_r: file(name: { eq: "doug-r" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      kyisha_s: file(name: { eq: "kyisha-s" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      matt_s: file(name: { eq: "matt-s" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `
  )

  // Set ImageData.
  const doug_r = data.doug_r?.childImageSharp?.fluid
  const kyisha_s = data.kyisha_s?.childImageSharp?.fluid
  const matt_s = data.matt_s?.childImageSharp?.fluid

  return (
    <div className="col-lg-12 testimonial-container">
      <div className="row">
        <div className="testimonial-slide col-md-4">
          <div className="testimonial-image-container">
            <Img fluid={doug_r} alt="Doug R" />
            <p className="text-center text-white testimonial-author"><em>Doug R.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27" alt="testimonial quote"/> <img src={PositiveMin} height="16" width="16" alt="satisfaction guaranteed" /> Convenient scheduling, friendly and helpful staff. I actually enjoyed my visit to DGC. I feel I received a <button className="read-more"><span className="text-black">...</span>read more</button><span className="read-less-desc" id="doug-readmore">thorough evaluation and great results from the procedure. I walked out feeling like I made some new friends and confidence in my treatment. <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide col-md-4">
          <div className="testimonial-image-container">
            <Img fluid={kyisha_s} alt="Kyisha S" />
            <p className="text-center text-white testimonial-author"><em>Kyisha S.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27" alt="testimonial quote"/> <img src={PositiveMin} height="16" width="16" alt="satisfaction guaranteed" /> the dentists is such a lovely sight to look at his face makes you forget the pain.</span>
          </div>
        </div>
        <div className="testimonial-slide col-md-4">
          <div className="testimonial-image-container">
            <Img fluid={matt_s} alt="Matt S" />
            <p className="text-center text-white testimonial-author"><em>Matt S.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27" alt="testimonial quote"/> <img src={PositiveMin} height="16" width="16" alt="satisfaction guaranteed" /> Dr. Vogt is an excellent dentist who does quality work. Highly recommend!</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialSlider
