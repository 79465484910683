import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'

const OurMission = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        section_3_bg: file(name: { eq: "placeholder" }) {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const section_3_bg = data.section_3_bg.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className="home-section-3-bg page-section"
      fluid={section_3_bg}
      backgroundColor={`#e9f4f8`}
      alt="The Dentists at Gateaway Crossing Services"
      >
      <div className ="col-lg-12" id ="color-overlay"></div>
      <section id="home-section-3" className="page-section text-light">
        <Container>
          <div className="col-lg-12">
            <div className="row">
              <div className ="col-md-12">
                <h2 className="text-center">Our <strong>Mission</strong>: To provide exceptional care and service with an attitude of compassion, positivity, and generosity.</h2>
                <p className="subheader-small"> We care about people, not just teeth. </p>
                <p> We started our practice with the mission of delivering an exceptional dental experience for our patients. Everything we do is centered around great clinical care, as well as your convenience and comfort. We thrive on positivity and strive to build relationships that last a lifetime. </p>
                <p> Whether you’re in need of a simple check-up or a total smile makeover, we invite you to be a part of our family here at The Dentists at Gateway Crossing.</p>
              </div>
              <div className= "col-lg-12 text-center">
                <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Appoinment </button></a>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(OurMission)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection
