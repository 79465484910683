import React, {lazy, Suspense} from 'react'
import { graphql, Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Helmet } from 'react-helmet'
import TestimonialSlider from 'components/sliders/testimonial-sliders'
import ClientsTestimonials from 'components/clients-testimonials'
import InsuranceSlider from 'components/sliders/insurance-sliders'
import InsuranceLogos from 'components/insurance-logos'
import ButtonBlack from 'components/button/button-black'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import Video from 'components/video'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import HomeOfficeSection from 'components/pages/home/HomeOfficeSection'
import DrVogtSection from 'components/pages/home/DrVogtSection'
import OurMission from 'components/pages/home/OurMission'
import HeroVideo from 'src/videos/dr-vogt-video-header.webm'
import HeroVideoMp4 from 'src/videos/dr-vogt-video-header.mp4'
import { FaStar } from "@react-icons/all-files/fa/FaStar";

interface Props {
  data: IndexQueryQuery
  location: Location
}

const renderLoader = () => <p>Loading</p>;

const HomePage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const posts = data.remark.posts
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const section2_bg = data.section2_bg?.childImageSharp?.fluid
  const preventive_dentistry = data.preventive_dentistry?.childImageSharp?.fluid
  const restorative_dentistry = data.restorative_dentistry?.childImageSharp?.fluid
  const cosmetic_dentistry = data.cosmetic_dentistry?.childImageSharp?.fluid
  const implant_dentistry = data.implant_dentistry?.childImageSharp?.fluid
  const oral_surgery = data.oral_surgery?.childImageSharp?.fluid
  const periodontal_treatment = data.periodontal_treatment?.childImageSharp?.fluid
  const sedation_dentistry = data.sedation_dentistry?.childImageSharp?.fluid
  const root_canal_therapy = data.root_canal_therapy?.childImageSharp?.fluid
  const pediatric_dentistry = data.pediatric_dentistry?.childImageSharp?.fluid
  const family_dentistry = data.family_dentistry?.childImageSharp?.fluid
  
  return (
    <Layout location={location}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>McCordsville Dentist Office The Dentists At Gateway Crossing</title>
        <meta name="description" content="The Dentists at Gateway Crossing is a top McCordsville family dentistry offering cosmetic dentistry, pediatric dentistry and more. " />
        <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
        <meta property="og:description" content="The Dentists at Gateway Crossing is a top McCordsville family dentistry offering cosmetic dentistry, pediatric dentistry and more. " />
        <meta property="og:url" content="https://thedentistsatgc.com/" />
        <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
        <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:secure_url" content={OGImage} />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:alt" content="McCordsville Dentist" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
        <meta name="twitter:description" content="The Dentists at Gateway Crossing is a top McCordsville family dentistry offering cosmetic dentistry, pediatric dentistry and more. " />
        <meta name="twitter:image" content={OGImage} />
        <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
        <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
        <meta name="msapplication-TileImage" content={Logo} />
        <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
      </Helmet>
      <section id="hero-section" className="banner home text-center">
        <div className="col-lg-12" id="color-overlay-dark"></div>
        <video id="videobcg" autoPlay loop muted playsInline poster={OGImage} src={HeroVideoMp4}>
          <source src={HeroVideo} type="video/webm" />
          <source src={HeroVideoMp4} type="video/mp4" />Sorry, your browser does not support HTML5 video.
        </video>
        <Container>
          <div className="col-lg-12 text-center">
            <h1 className="text-white">
              THE DENTISTS
            </h1>
            <h2 className="text-white">AT GATEWAY CROSSING</h2>
          </div>
          <div className="col-lg-12">
            <p className="hero-text text-light">
              <em>“Very professional team comprised of genuinely great people. Great experience.”</em>
            </p>
            <div className="home-stars-container">
              <FaStar size="35px" className="star svg-icon-top"/><FaStar size="35px" className="star svg-icon-top"/><FaStar size="35px" className="star svg-icon-top"/><FaStar size="35px" className="star svg-icon-top"/><FaStar size="35px" className="star svg-icon-top"/>
            </div>
            <p className="hero-text text-light">
              <em>JEFF C., MCCORDSVILLE</em>
            </p>
            <div className="text-center">
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded bg-main"> Book Online </button></a>
            </div>
          </div>
        </Container>
      </section>
      <HomeOfficeSection />
      <DrVogtSection />
      <OurMission />
      <section id="home-section-4" className="page-section text-center">
        <Container>
          <div className="col-lg-12">
            <h2>Our Dental Services</h2>
          </div>
          <div className= "col-lg-12 m-b-20">
            <p>We try our very best to make every visit to our dentist office the best visit you’ve ever had. You can count on us to be kind, caring, and gentle with your teeth, gums, and overall oral health. We offer many dental services in our practice to help take care of you and your family.  </p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-4">
            <Link to ="/services/preventative-dentistry/">
              <div className="columns">
                <Img fluid={preventive_dentistry} alt="Preventive Dentistry" />
                <div className="column-desc">
                  <p className="subheader-small">Preventative Dentistry</p>
                  <p>We emphasize prevention, always. We use high-end materials and technology to achieve longevity in our patients' treatment.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to ="/services/restorative-dentistry/">
              <div className="columns">
                <Img fluid={restorative_dentistry} alt="Restorative Dentistry" />
                <div className="column-desc">
                  <p className="subheader-small">Restorative Dentistry</p>
                  <p>You can trust in our team's expertise to repair damaged teeth with minimally invasive techniques.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to ="/services/cosmetic-dentistry/">
              <div className="columns">
                <Img fluid={cosmetic_dentistry} alt="Cosmetic Dentistry" />
                <div className="column-desc">
                  <p className="subheader-small">Cosmetic Dentistry</p>
                  <p>We examine over 41 different factors of the face and mouth before we design a smile.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to ="/services/implant-dentistry/">
              <div className="columns">
                <Img fluid ={implant_dentistry} alt="Implant Dentistry"/>
                <div className="column-desc">
                  <p className="subheader-small">Implant Dentistry</p>
                  <p>Whether you are missing one tooth or all of your teeth, our team will help you regain the smile you deserve.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to ="/services/oral-surgery/">
              <div className="columns">
                <Img fluid={oral_surgery} alt="Oral Surgery" />
                <div className="column-desc">
                  <p className="subheader-small">Oral Surgery</p>
                  <p>We understand how bad a toothache feels, especially in an emergency, and we are here to help.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to ="/services/periodontal-treatment/">
              <div className="columns">
                <Img fluid={periodontal_treatment} alt="Periodontal Treatment" />
                <div className="column-desc">
                  <p className="subheader-small">Periodontal Treatment</p>
                  <p>We ensure that our patients' gums are healthy to provide the foundation for a healthy mouth.</p>
                </div>
              </div>
            </Link>
          </div>
        </Container>
      </section>
      <section id="home-section-5" className="page-section text-center">
        <Container>
          <div className="col-lg-12">
            <div className ="col-md-12">
              <h2>Our Reviews</h2>
            </div>
          </div>
          <ClientsTestimonials />
          <div className="col-lg-12">
            <Link to="/testimonials"><button className="btn-rounded btn-solid bg-main see-all-reviews"> See All Reviews </button></Link>
          </div>
        </Container>
      </section>
      <section id="home-section-6" className="page-section text-center">
        <Container>
          <div className="col-lg-12">
            <h2 className="we-accept">We Accept Your Insurance</h2>
          </div>
          <InsuranceLogos />
        </Container>
      </section>
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query HomePageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    },
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            author
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxHeight: 362) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    implant_dentistry: file(name: { eq: "implant-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    oral_surgery: file(name: { eq: "oral-surgery" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    periodontal_treatment: file(name: { eq: "periodontal-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    preventive_dentistry: file(name: { eq: "preventive-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    restorative_dentistry: file(name: { eq: "restorative-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cosmetic_dentistry: file(name: { eq: "cosmetic-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    sedation_dentistry: file(name: { eq: "sedation-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    root_canal_therapy: file(name: { eq: "root-canal-therapy" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pediatric_dentistry: file(name: { eq: "pediatric-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    family_dentistry: file(name: { eq: "family-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "dentist-chair" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
