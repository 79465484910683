import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BtnSolid from 'components/button/button-solid'
import BackgroundImage from 'gatsby-background-image'
import Map from 'components/map-embed'
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";


const HomeOfficeSection = ({ className, props }) => {
  const data = useStaticQuery(
    graphql`
      query {
        office_background: file(name: { eq: "crossing-min" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        office_map_sm: file(name: { eq: "the-dentists-at-gc-map-sm" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const office_background = data.office_background.childImageSharp.fluid
  const office_map_sm = data.office_map_sm.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="div"
      className="hero-section-bg-1"
      fluid={office_background}
      backgroundColor={`#e9f4f8`}
      alt="Our Office - The Dentists at Gateway Crossing"
    >
      <div id="home-section-1" className="page-section">
        <ContainerFluid>
          <div className="col-lg-5">
            <div className="col-lg-12" id="color-overlay-dark"></div>
          </div>
          <div className="col-lg-7 office-column">
            <div className ="" id="color-overlay"></div>
            <h2 className="text-white text-center">Our <strong>McCordsville Dental Office</strong> </h2>
            <p className="text-white text-center"><em> Our brand new McCordsville dentist office is conveniently located on Pendleton Pike just down the road from Meijer and in the same plaza as “The Grill.” We chose our location with the nearby Fishers and Indianapolis areas in mind. We built and designed a state of the art dental office with brand new equipment to provide our patients with the very best in gentle dentistry.</em></p>
            <Map mapSrcURL="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d391844.8284369874!2d-85.930534!3d39.89019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6f4e92eaa919dbc5!2sThe%20Dentists%20at%20Gateway%20Crossing!5e0!3m2!1sen!2sus!4v1613657636924!5m2!1sen!2sus" mapThumbSrcURL={office_map_sm} alt="The Dentists at Gateway Crossing Google Maps Location" />
            <h2 className="text-white m-t-20">Give <strong>Us A Call</strong></h2>
            <BtnSolid url="tel:3176439434" class="btn-rounded btn-solid bg-main cta-btn" label="(317) 643-9434" />
          </div>
        </ContainerFluid>
      </div>
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(HomeOfficeSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection
